let selOpts = [];

$(document).ready(() => {
  // init the newly added states dropdown
  $('#state-dropdown').select2({
    placeholder: 'Choose one',
    searchInputPlaceholder: 'Search',
    allowClear: true,
  });
  $('#city-dropdown').select2({
    placeholder: 'Choose one',
    searchInputPlaceholder: 'Search',
    allowClear: true,
  });

  $('#data-element-table').DataTable({
    data: selOpts,
    columns: [{ title: 'Hospital' }, { title: 'City' }],
    responsive: true,
    pageLength: 10,
    searchHighlight: true,
    language: {
      searchPlaceholder: 'Search...',
      sSearch: '',
      lengthMenu: '_MENU_ items/page',
      emptyTable: 'Please choose a state and city to search for your provider.',
      infoEmpty: '.',
      paginate: {
        previous: '«',
        next: '»',
      },
    },
  });

  $('#state-dropdown').on('change', function() {
    $('#city-dropdown').empty();
    $('#city-dropdown').html('<option value="">Select City </option>');
    $('#data-element-body').html('');
    $('#data-element-table')
      .dataTable()
      .fnClearTable();

    const state_id = this.value;

    if (state_id !== '') {
      $.ajax({
        url: `https://pulseweb.pluto-service.com/api/get-cities?state=${state_id}`,
        type: 'GET',
        success(result) {
          let res = '';
          for (let i = 0; i < result.content.length; i++) {
            const id = result.content[i].city;
            const val = result.content[i].city;
            res += `<option   value='${id}' >${val}</option>`;
          }
          $('#city-dropdown').append(res);
          // $('#city-dropdown').select2('open')
        },
      });

      $.ajax({
        url: `https://pulseweb.pluto-service.com/api/get-hospitals?state=${state_id}`,
        type: 'GET',
        success(result) {
          selOpts = [];
          if (result.content) {
            for (let i = 0; i < result.content.length; i++) {
              const city = result.content[i].city;
              const hosp = result.content[i].name;
              selOpts.push([hosp, city]);
              // selOpts += "<option   value='" + id + "' >" + val + "</option>";
              // console.log(val);
              // selOpts += '<tr class="readonly"> <td>' + val + '</td></tr>'
            }
            $('#data-element-table')
              .dataTable()
              .fnAddData(selOpts);
          }
        },
      });
    }
  });

  $('#city-dropdown').on('change', function() {
    $('#data-element-body').html('');
    $('#data-element-table')
      .dataTable()
      .fnClearTable();

    const state_id = $('#state-dropdown')
      .find(':selected')
      .val();
    // var state_name = $('#state-dropdown').find(":selected").text();
    const city = this.value;

    $.ajax({
      url: `https://pulseweb.pluto-service.com/api/get-hospitals?state=${state_id}&city=${city}`,
      type: 'GET',
      success(result) {
        selOpts = [];
        if (result.content) {
          for (let i = 0; i < result.content.length; i++) {
            const val = result.content[i].name;
            let city_name = result.content[i].city;
            if (city_name === '') {
              city_name = city;
            }
            selOpts.push([val, city_name]);
            // selOpts += "<option   value='" + id + "' >" + val + "</option>";
            // console.log(val);
            // selOpts += '<tr class="readonly"> <td>' + val + '</td></tr>'
          }
          $('#data-element-table')
            .dataTable()
            .fnAddData(selOpts);
        }
        // console.log(selOpts);
      },
    });
  });

  function onHashChange() {
    const hash = window.location.hash;

    if (hash === '#search-header') {
      // using ES6 template string syntax
      $(`#advanced-search-tab`).trigger('click');
    }
  }

  window.addEventListener('hashchange', onHashChange, false);
  onHashChange();
});

const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

states.forEach(state => {
  $('#state-dropdown').append(
    `<option value='${state.abbreviation}'>${state.name}</option>`
  );
});
